@use "variables" as *;

html {
  overflow-x: hidden;
  font-size: $base-font-size;
  height: 100%;
}

body {
  scroll-behavior: smooth;
  overflow-x: hidden;
  font-size: $base-font-size;
  margin: 0;
  font-family: Montserrat, sans-serif;
  height: 100%;
}

#reports:has(.specialisation-reports) {
  background-color: rgb(217 217 217 / 25%);
  min-height: 100%;
}

.modal-container {
  padding: calculate-rem(10px);

  &__title {
    font-size: calculate-rem(22px);
    font-family: Montserrat-medium, sans-serif;
    line-height: 1.6;
  }

  .modal-content:has(.loader) {
    padding: 15px;
    justify-content: center;
  }

  .loader {
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgb(255 255 255 / 60%);
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;

    .mat-progress-spinner circle, .mat-spinner circle {
      stroke: $secondary-color;
    }
  }
}

.toast-container .ngx-toastr {
  box-shadow: unset !important;
}

.toast-container .ngx-toastr:hover {
  box-shadow: unset !important;
}

.tw-form-field {
  &.mat-form-field-appearance-outline .mat-form-field-flex {
    height: 60px;
  }

  &.mat-form-field-appearance-outline {
    .mat-form-field-suffix {
      top: 0;
    }
  }

  &.mat-form-field:not(.mat-form-field-appearance-legacy) .mat-form-field-prefix .mat-icon,
  &.mat-form-field:not(.mat-form-field-appearance-legacy) .mat-form-field-suffix .mat-icon {
    color: rgb(0 0 0 / 54%);
  }
}

.mat-calendar-body-selected {
  background-color: $secondary-color;
}

.tw-form-field, .tw-form-field-area {
  mat-label {
    color: $secondary-color;
  }

  &.mat-form-field-appearance-outline .mat-form-field-outline-thick {
    color: $secondary-color;
  }

  mat-error {
    font-family: Tahoma, sans-serif;
  }

  .hide-icon {
    color: $secondary-color;
    cursor: pointer;

    &:active, &:hover {
      color: $secondary-hover-color;
    }
  }

  &.mat-form-field-appearance-outline .mat-form-field-outline {
    background-color: white;
    border-radius: 5px;
  }
}

.mat-option.mat-focus-indicator.mat-selected.mat-active {
  color: $secondary-color;
}

.mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background-color: rgb(36 41 67 / 10%);
}

.select-wrapper {
  mat-error {
    font-size: 75%;
    padding-left: 10px;
  }
}

.action-buttons {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cdk-overlay-container:has(.additional-info-modal) {
  background-color: rgb(0 0 0 / 85%);
}

.mat-dialog-container:has(.ms-modal) {
  overflow-y: auto;
}

:not(.administrator-select).select-row {
  padding: 15px;
  border-radius: 5px;
  background-color: white;

  .select-row-select-wrapper {
    width: 50%;
  }
}

.administrator-select {
  .select-row {
    padding: 15px;
    border-radius: 5px;
    background-color: white;

    .select-row-select-wrapper {
      width: 100%;
    }
  }
}


.select-row-title {
  font-size: calculate-rem(16px);
  color: $primary-color;
  font-family: Montserrat-medium, sans-serif;
}

.select-row-info {
  mat-icon {
    min-width: 24px;
  }

  color: $primary-color;

  word-break: break-all;
  font-size: calculate-rem(14px);
}

.ng-select .ng-spinner-loader {
  display: none;
}

.status-drag-drop-list {
  width: 100%;
  max-width: 100%;
  border: solid 1px #ccc;
  min-height: 60px;
  display: block;
  background: white;
  border-radius: 4px;
  overflow: hidden;
}

.status-drag-drop-item {
  padding: 20px 10px;
  border-bottom: solid 1px #ccc;
  color: rgb(0 0 0 / 87%);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  cursor: move;
  background: white;
  font-size: 14px;

  .edit {
    color: $secondary-color;

    &:active, &:hover {
      color: $secondary-hover-color;
    }
  }
}

.topic-drag-drop-list {
  width: 100%;
  max-width: 100%;
  border: solid 1px #ccc;
  min-height: 45px;
  display: block;
  background: white;
  border-radius: 4px;
  overflow: hidden;
}

.topic-drag-drop-box {
  padding: 20px 10px;
  border-bottom: solid 1px #ccc;
  color: rgb(0 0 0 / 87%);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  cursor: move;
  background: white;
  font-size: 14px;

  .topic-column {
    display: flex;
    flex-direction: column;

    max-width: 1100px;
    white-space: normal;
    word-wrap: break-word;
  }

  .drop-title {
    color: rgb(0 0 0 / 54%);
  }

  .info {
    color: $accent-color;

    &:active, &:hover {
      color: $accent-hover-color;
    }
  }

  .edit {
    color: $secondary-color;

    &:active, &:hover {
      color: $secondary-hover-color;
    }
  }

  .delete {
    color: $delete-color;

    &:active, &:hover {
      color: $delete-hover-color;
    }
  }
}

.cdk-drag-placeholder {
  opacity: 0;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.topic-drag-drop-box:last-child {
  border: none;
}

.topic-drag-drop-list.cdk-drop-list-dragging .topic-drag-drop-box:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-drag-preview {
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%),
  0 8px 10px 1px rgb(0 0 0 / 14%),
  0 3px 14px 2px rgb(0 0 0 / 12%);
}

.status-drag-drop-list.cdk-drop-list-dragging .status-drag-drop-item:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.required-checkbox-section {
  .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background-color: $secondary-color;
  }

  .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element, .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
    background-color: $secondary-color;
  }
}

.primary-report-accordion .secondary-accordion,
.secondary-accordion {
  .mat-expansion-panel-header-description,
  .mat-expansion-indicator::after {
    color: rgb(0 0 0 / 54%);
  }

  .mat-expansion-panel-body {
    padding: calculate-rem(15px) calculate-rem(10px);
  }
}

.primary-report-accordion {
  .mat-expansion-panel-header-description,
  .mat-expansion-indicator::after {
    color: white;
  }

  .mat-expansion-panel-body {
    padding: 0;
  }
}

.tw-accordion {
  .mat-expansion-panel-header.mat-expanded {
    height: unset;
    background-color: rgb(217 217 217 / 30%);

    &:hover, &:focus {
      background-color: rgb(217 217 217 / 20%);
      transition: opacity 0.8s ease-in-out;
    }
  }

  .mat-expansion-panel-body:has(.no-data) {
    padding: 15px;
  }

  .mat-expansion-panel-body:not(:has(.no-data)) {
    padding: 0;
  }

  .mat-expansion-panel {
    border-radius: 0;
  }

  .mat-expansion-panel-spacing {
    margin: 0;
  }

  mat-expansion-panel-header {
    height: unset;

    background-color: rgb(217 217 217 / 30%);
    padding: 0 20px 0 0;
    border-bottom: 1px solid rgb(36 41 67 / 20%);
    border-radius: unset;

    &:hover, &:focus {
      background-color: rgb(217 217 217 / 40%);
      transition: opacity 0.8s ease-in-out;
    }
  }

  mat-panel-description {
    margin-right: calculate-rem(10px);
    display: flex;
    justify-content: flex-end;
  }

  .mat-expansion-panel-content:has(.no-data) {
    font-family: Montserrat-italic, sans-serif;
    text-align: center;
  }

  .mat-list-base .mat-list-option {
    font-size: calculate-rem(14px);
  }
}

.tw-list, .tw-accordion {
  .primary-icon {
    color: $secondary-color;

    &:active, &:hover {
      color: $secondary-hover-color;
    }
  }

  .delete-icon {
    color: $delete-color;

    &:active, &:hover {
      color: $delete-hover-color;
    }
  }
}

.tw-tab-group {
  &.mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar {
    background-color: $secondary-color;
  }
}

.p-link:focus {
  box-shadow: 0 0 0 0.2rem $secondary-color;
}

.p-galleria .p-galleria-item-nav {
  background: $accent-color;
}

.p-galleria .p-galleria-item-nav:not(.p-disabled):hover {
  background: rgb(217 217 217 / 85%);
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;

  .mat-progress-spinner circle, .mat-spinner circle {
    stroke: $secondary-color;
  }
}

.tw-card {
  border-radius: 5px;
}

.mat-dialog-container:has(.manage-kkk-category), .mat-dialog-container:has(.manage-kkk-ability),
.mat-dialog-container:has(.manage-kkk-subject-group), .mat-dialog-container:has(.manage-knowledge-group) {
  padding: calculate-rem(5px);

}

.manage-kkk-category, .manage-kkk-ability, .manage-kkk-subject-group, .manage-knowledge-group {
  .modal-container {
    &__title {
      font-size: calculate-rem(18px);
      line-height: 1.2;
    }
  }
}

.kkk-form-field {
  mat-label {
    color: $secondary-color;
  }

  &.mat-form-field-appearance-outline .mat-form-field-outline-thick {
    color: $secondary-color;
  }

  mat-error {
    font-family: Tahoma, sans-serif;
  }

  font-size: 12px; // example

  &.mat-form-field-appearance-outline .mat-form-field-outline {
    font-size: 12px;
  }
}

.save-icon {
  color: $secondary-color;

  &:active, &:hover {
    color: rgb(131 185 201);
  }

  &:disabled {
    color: rgb(80 173 201 / 45%);
    cursor: not-allowed;
  }
}

.cancel-icon {
  color: $accent-color;

  &:active, &:hover {
    color: rgb(196 196 196);
  }

  &:disabled {
    color: rgb(217 217 217 / 75%);
  }
}

@media (max-width: $xxl) {
  .select-row {
    .select-row-select-wrapper {
      width: 50%;
    }
  }
}

@media (max-width: $lg) {
  .topic-drag-drop-box {
    .topic-column {
      max-width: 400px;
    }
  }
}

@media (max-width: $md) {
  .select-row {
    .select-row-select-wrapper {
      width: 75%;
    }
  }

  .topic-drag-drop-box {
    .topic-column {
      max-width: 250px;
    }
  }
}

@media (max-width: $sm) {
  .modal-container {
    &__title {
      font-size: calculate-rem(18px);
    }
  }

  .topic-drag-drop-box {
    .topic-column {
      max-width: 170px;
    }
  }

  .action-buttons {
    flex-direction: column;
  }

  .select-row {
    .select-row-select-wrapper {
      width: 100%;
    }
  }
}

@media (hover: hover) {
  .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgb(80 173 201 / 30%);
  }
}

.mat-datepicker-toggle-active {
  color: $secondary-color;
}

.mat-calendar-body-in-range::before {
  background: rgb(217 217 217 / 45%);
}
